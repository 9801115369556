<template>
  <div class="container">
    <div class="toolbar">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="medium"
        v-on:click="showAddRoleDialog"
        class="margin-15"
        >新增权限</el-button
      >
    </div>
    <el-table :data="list" border size="small">
      <el-table-column type="index" width="55" label="序号"> </el-table-column>
      <el-table-column prop="name" label="权限名称"> </el-table-column>
      <el-table-column prop="menulevel" label="权限等级"> </el-table-column>
      <el-table-column prop="menuicon" label="图标"> </el-table-column>
      <el-table-column prop="url" label="url"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="220">
        <template slot-scope="scope">
          <el-button @click="editDetails(scope.row)" type="text" size="small"
            >修改权限</el-button
          >
          <el-button @click="del(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-size="params.size"
        :total="detail.totalRecords"
      >
      </el-pagination>
    </div>
    <el-dialog
      append-to-body
      title="权限管理"
      :visible.sync="editDetailDialog"
      width="50%"
    >
      <el-form
        label-width="100px"
        :rules="formRole"
        :model="form"
        ref="editForm"
      >
        <el-form-item label="权限上级">
          <el-select
            style="width: 200px"
            v-model="formid"
            placeholder="请选择上級没有则不选"
            clearable=""
            @change="changeId"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in selectList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入权限名称"></el-input>
        </el-form-item>
        <el-form-item label="url" prop="url">
          <el-input v-model="form.url" placeholder="请输入url"></el-input>
        </el-form-item>
        <!-- <el-form-item label="pid" prop="pid">
          <el-input v-model="form.pid" placeholder="请输入pid"></el-input>
        </el-form-item> -->
        <el-form-item label="图标">
          <el-input v-model="form.menuicon" placeholder="请输入图标"></el-input>
        </el-form-item>

        <el-form-item label="排序顺序">
          <el-input-number v-model="form.ordernum"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button
          plain
          type="success"
          size="medium"
          v-on:click="updateRoleName"
          >确认修改</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      append-to-body
      title="新增权限"
      :visible.sync="addRoleDialog"
      width="50%"
    >
      <el-form
        label-width="100px"
        :rules="formRole"
        :model="form"
        ref="addForm"
      >
        <el-form-item label="权限上级">
          <el-select
            style="width: 200px"
            v-model="formid"
            placeholder="请选择上級没有则不选"
            clearable=""
            @change="changeId"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in selectList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入权限名称"></el-input>
        </el-form-item>
        <el-form-item label="url" prop="url">
          <el-input v-model="form.url" placeholder="请输入url"></el-input>
        </el-form-item>
        <!-- <el-form-item label="pid" prop="pid">
          <el-input v-model="form.pid" placeholder="请输入pid"></el-input>
        </el-form-item> -->
        <el-form-item label="图标">
          <el-input v-model="form.menuicon" placeholder="请输入图标"></el-input>
        </el-form-item>
        <el-form-item label="排序顺序">
          <el-input-number v-model="form.ordernum"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="success" size="medium" v-on:click="addRole"
          >确认添加</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rolecode: "",
      form: {
        name: "",
        menulevel: "1",
        pid: "-1",
        url: "",
        ordernum: 1,
        menuicon: "",
      },
      formRole: {
        name: [{ required: true, message: "请输入权限名称", trigger: "blur" }],
        url: [{ required: true, message: "请输入url", trigger: "blur" }],
      },
      list: [],
      parentList: [],
      roleItem: {},
      detail: {},
      params: {
        page: 1,
        pageSize: "10",
      },
      pageIndex: 1,
      pageSize: "8",
      totalData: 0,
      tableData: [],
      menuList: [],
      menuDetail: [],
      rolename: "",
      activeName: 0,
      viewDetailDialog: false,
      viewDetailObj: null,
      addRoleDialog: false,
      addRolename: "",
      editDetailObj: "",
      editDetailDialog: false,
      selectList: [],
      formid: "",
    };
  },

  mounted() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    this.initList();
    this.select();
  },
  methods: {
    async initList() {
      await this.$https.limitService
        .getMenuPageList(this.params)
        .then((res) => {
          this.list = res.list;
          this.detail = res;
          this.parentList = res.list.filter((e) => e.menulevel == 1);
        });
    },
    async select() {
      let params = {
        page: 1,
        pageSize: "9999",
      };
      await this.$https.limitService.getMenuPageList(params).then((res) => {
        this.selectList = res.list;
        // .filter((e) => e.menulevel == 1);
      });
    },
    addRole() {
      if (
        this.roleItem &&
        this.roleItem.hasOwnProperty.call(this.roleItem, "pid")
      ) {
        this.form.pid = this.roleItem.id || "-1";
        this.form.menulevel = Number(this.roleItem.menulevel) + 1;
      } else {
        this.form.pid = "-1";
        this.form.menulevel = "1";
      }
      this.$refs["addForm"].validate(async (valid) => {
        if (valid) {
          await this.$https.limitService.insertMenu(this.form);
          this.$message({
            showClose: true,
            message: "添加成功",
            type: "success",
          });
          this.addRoleDialog = false;
          this.initList();
          this.select();
        }
      });
    },
    updateRoleName() {
      if (
        this.roleItem &&
        this.roleItem.hasOwnProperty.call(this.roleItem, "pid")
      ) {
        this.form.pid = this.roleItem.id || "-1";
        this.form.menulevel = Number(this.roleItem.menulevel) + 1;
      } else {
        this.form.pid = "-1";
        this.form.menulevel = "1";
      }
      this.$refs["editForm"].validate(async (valid) => {
        if (valid) {
          await this.$https.limitService.updateMenu(this.form);
          this.$message({
            showClose: true,
            message: "添加成功",
            type: "success",
          });
          this.editDetailDialog = false;
          this.initList();
          this.select();
        }
      });
    },
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await this.$https.limitService.delMenu({ id: row.id });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.initList();
        this.select();
      });
    },
    handleSelectionChange() {},
    handleClick() {
      this.rolecode = this.activeName;
      this.getRoleMenuList();
    },
    editDetails(row) {
      this.form = row;
      if (row.pid > 0) {
        this.formid = row.pid;
        this.roleItem = this.selectList.find((e) => e.id == row.pid);
      }
      this.editDetailDialog = true;
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.initList();
    },
    handleSizeChange(val) {
      this.params.page = val;
      this.initList();
    },
    showAddRoleDialog() {
      this.form = {
        name: "",
        menulevel: "1",
        pid: "-1",
        url: "",
        ordernum: 1,
        menuicon: "",
      };
      this.addRoleDialog = true;
      this.roleItem = {};
      this.formid = "";
    },
    changeId(vm) {
      console.log(vm);
      this.roleItem = this.selectList.find((e) => e.id == vm);
    },
  },
};
</script>

<style scoped lang="scss">
.toolbar {
  margin: 15px 0;
}
.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
</style>
